import axios from "axios";

let config = null;

export async function getConfig() {
  if (config === null) {
    const { data } = await axios.get("/client_config.json");
    config = data;
  }
  return config;
}
