import Axios from "axios";
import { setupCache } from "axios-cache-interceptor";

import { getConfig } from "../config";

const instance = Axios.create();
const axios = setupCache(instance);

const config = await getConfig();

const apiBase = config.api.base.startsWith("/")
  ? new URL(
      config.api.base,
      `${window.location.protocol}//${window.location.host}`
    ).href
  : config.api.base;

export async function getEmails(q = {}) {
  const url = new URL("emails", apiBase).href;
  const { data } = await axios.get(url, {
    cache: {
      ttl: 1000 * 60 * 1, // one minute
    },
    params: { q: JSON.stringify(q) },
  });
  return data;
}

export async function getEmailsCount(q = {}) {
  const url = new URL("emails/count", apiBase).href;
  const { data } = await axios.get(url, { params: { q: JSON.stringify(q) } });
  return data.count;
}

export async function getEmail(id) {
  const url = new URL(`emails/${id}`, apiBase).href;
  const { data } = await axios.get(url);
  return data;
}

export async function putEmail(email) {
  const url = new URL(`emails/${email._id}`, apiBase).href;
  const { data } = await axios.put(url, email);
  return data;
}
