import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import Markdown from "react-markdown";

export default function Email({ email }) {
  return (
    <Box>
      <Box>
        <Box sx={{ mb: 2 }}>
          <Box>
            <Typography variant="h2"> {email.headers.subject}</Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">{email.snippet}</Typography>
          </Box>
        </Box>
        <Box sx={{ pb: 2 }}>
          <Typography variant="subtitle1">{email.headers.from}</Typography>
        </Box>
        <Box sx={{}}>
          <Stack direction="row" spacing={1} flexWrap={"wrap"}>
            {email &&
              email.ner.map((tag) => (
                <Box sx={{ pb: 1, ml: 2, mr: 2 }}>
                  <Chip sx={{ p: 1 }} label={tag} variant="filled" />
                </Box>
              ))}
          </Stack>
        </Box>
      </Box>
      {email.summary && (
        <Box>
          <Typography variant="h4">Summary</Typography>
          <Box sx={{ pl: 3, pr: 3 }}>
            <Markdown>{email.summary.text.replace(/\\n/g, "\n")}</Markdown>
          </Box>
        </Box>
      )}
      <Box>
        <Alert severity="info" sx={{ mb: 1 }}>
          Images have been blocked to avoid tracking and detection of this tool.
        </Alert>
        <Alert severity="warning" sx={{ mb: 1 }}>
          Clicking on links within the email below could allow tracking.
        </Alert>
        <iframe
          title={email.headers.subject}
          srcDoc={email.htmlBody}
          width="100%"
          height={800}
          csp="default-src 'self'; img-src 'none';"
        />
      </Box>
    </Box>
  );
}
