import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Email";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

import Pagination from "@mui/material/Pagination";

const Shadow = () => (
  <Stack spacing={1} direction="row" alignItems="center">
    <Skeleton variant="rounded" width={10} height={70} />
    <Skeleton variant="circular" width={50} height={50} />
    <Skeleton variant="rounded" width="100%" height={70} />
  </Stack>
);

const Shadows = ({ count = 5 }) => (
  <Stack Stack spacing={1}>
    {[...Array(count)].map((i) => (
      <Shadow key={`shadow-key-${i}`} />
    ))}
  </Stack>
);

export default function EmailsList({ emails, onClick }) {
  const [page, setPage] = useState(
    JSON.parse(window.localStorage.getItem("SHOW_EMAILS_PAGE")) || 1
  );
  const pageSize = 10;
  const numPages = Math.ceil(emails.length / pageSize);

  useEffect(() => {
    const storedPage = JSON.parse(
      window.localStorage.getItem("SHOW_EMAILS_PAGE")
    );
    if (page !== null && storedPage !== page) {
      window.localStorage.setItem("SHOW_EMAILS_PAGE", JSON.stringify(page));
    }
  }, [page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const gbColour = (interesting) => {
    if (interesting === false) {
      return "primary.light";
    } else if (interesting === true) {
      return "success.light";
    }
    return "background.paper";
  };

  return (
    <Box>
      <Box spacing={2}>
        <Pagination count={numPages} page={page} onChange={handlePageChange} />
      </Box>
      <List sx={{ width: "100%" }}>
        {emails.length === 0 && (
          <Box>
            <Shadows count={10} />
          </Box>
        )}
        {emails
          .slice(page * pageSize - pageSize, page * pageSize)
          .map((email) => (
            <Box key={email._id}>
              <ListItem
                sx={{ bgcolor: gbColour(email.interesting), cursor: "pointer" }}
                onClick={() => onClick(email)}
              >
                <ListItemAvatar>
                  <Avatar>
                    <ImageIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={email.snippet}
                  secondary={email.headers.from}
                />
              </ListItem>

              <Divider variant="inset" component="li" />
            </Box>
          ))}
      </List>
      <Box spacing={2}>
        <Pagination count={numPages} page={page} onChange={handlePageChange} />
      </Box>
    </Box>
  );
}
