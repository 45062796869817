import { useEffect, useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";

import { getEmails } from "../../api";

import ListEmails from "../../components/emailsList";

function LinkRouter(props) {
  return <Link {...props} component={RouterLink} />;
}

export default function Emails() {
  let navigate = useNavigate();
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    getEmails().then((myEmails) => setEmails(myEmails));
  }, []);

  const handleClick = (email) => {
    navigate(`/emails/${email._id}`);
  };

  return (
    <Box>
      <Breadcrumbs aria-label="breadcrumb">
        <LinkRouter underline="hover" color="inherit" to="/">
          Home
        </LinkRouter>
        <Typography sx={{ color: "text.primary" }}>Emails</Typography>
      </Breadcrumbs>
      <ListEmails emails={emails} onClick={handleClick}></ListEmails>
    </Box>
  );
}
