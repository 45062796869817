import React from "react";
import { Routes, Route } from "react-router-dom";
import Container from "@mui/material/Container";

import ProtectedRoute from "./protectedRoute";
import Home from "./views/home";
import Emails from "./views/emails";
import Email from "./views/email";
import Report from "./views/report";
import IsInteresting from "./views/isInteresting";

function App() {
  return (
    <Container>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/emails/isinteresting"
          element={
            <ProtectedRoute>
              <IsInteresting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/emails/:id"
          element={
            <ProtectedRoute>
              <Email />
            </ProtectedRoute>
          }
        />
        <Route
          path="/report"
          element={
            <ProtectedRoute>
              <Report />
            </ProtectedRoute>
          }
        />
        <Route
          path="/emails"
          element={
            <ProtectedRoute>
              <Emails />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Container>
  );
}

export default App;
