import React, { useEffect, useState } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";

import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import EmailComponent from "../../components/email";

import { getEmail } from "../../api";

function LinkRouter(props) {
  return <Link {...props} component={RouterLink} />;
}

export default function EmailView() {
  const { id } = useParams(); // Extract the 'id' parameter from the URL
  const [email, setEmail] = useState(null); // State to store user data

  useEffect(() => {
    getEmail(id).then((myEmail) => setEmail(myEmail));
  }, [id]);

  if (!email) {
    return null;
  }

  return (
    <Box>
      <Breadcrumbs aria-label="breadcrumb">
        <LinkRouter underline="hover" color="inherit" to="/">
          Home
        </LinkRouter>
        <LinkRouter underline="hover" color="inherit" to="/emails">
          Emails
        </LinkRouter>
        <Typography sx={{ color: "text.primary" }}>
          {email.headers.subject}
        </Typography>
      </Breadcrumbs>
      {email && <EmailComponent email={email} />}
    </Box>
  );
}
