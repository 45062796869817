import React from "react";
import ReactWordcloud from "react-wordcloud";

import Box from "@mui/material/Box";

import wordCloudData from "./wordCloud.json";
import { Typography } from "@mui/material";

export default function Report() {
  const data = wordCloudData.map(({ _id, count }) => ({
    text: _id,
    value: count,
  }));
  return (
    <Box>
      <Box>
        <Typography variant="h4" gutterBottom>
          Exec summary
        </Typography>
        <Typography>
          Here is a 1000-word newsletter summarizing the most important point
          regarding cryptocurrency advice based on the provided text:
          Cryptocurrency Markets Brace for Regulatory Scrutiny The
          cryptocurrency market is facing growing regulatory scrutiny and
          uncertainty. Key developments covered in this newsletter indicate
          regulators are taking a harder look at the crypto industry, with
          significant actions against major players setting a precedent. Most
          notably, the Commodity Futures Trading Commission (CFTC) levied a
          $175,000 fine against Uniswap Labs for offering illegal tokenized
          derivatives on its platform. The CFTC determined that certain tokens
          provided leveraged exposure, acting as derivatives that should have
          been registered. While Uniswap Labs has taken measures to delist these
          tokens, the enforcement action sends a strong message to the industry
          about the importance of compliance. The CFTC order comes on the heels
          of the New York Attorney General issuing subpoenas to top Uniswap
          investors including Union Square Ventures and Andreessen Horowitz. The
          wide-ranging subpoenas suggest regulators aim to undertake a sweeping
          review of Uniswap’s activities. With Uniswap serving as one of the
          most popular decentralized exchanges, the increased oversight raises
          uncertainty for the broader DeFi sector. Other recent actions
          reinforce regulatory pressures on cryptocurrencies. The California
          Department of Justice settled with Robinhood for $3.9 million over
          issues related to restricted crypto withdrawals from 2018-2022. And
          the Federal Reserve issued a cease-and-desist order to United Texas
          Bank, citing deficiencies in risk management practices for
          crypto-related accounts. While the Trump family’s recently announced
          DeFi project plans to take advantage of deregulated decentralized
          finance, its stablecoin lending ambitions and ties to prominent
          political figures could invite regulatory scrutiny. Proposed
          legislation like the Lummis-Gillibrand Responsible Financial
          Innovation Act might provide more clarity on crypto rules, but
          partisan disagreements mean uncertainty persists. Some analysts posit
          regulators are using cryptocurrencies as a “political punching bag,”
          attempting to flex their oversight muscles. But it remains to be seen
          whether these early actions represent a tighter long-term stance by
          agencies like the SEC towards crypto and DeFi protocols. Much depends
          on the outcomes of ongoing investigations and lawsuits against major
          industry players like Kraken, Coinbase and Binance US. For now,
          cryptocurrency developers and investors should brace for more
          regulatory action. Compliance with anti-money laundering and
          securities regulations seems likely to accelerate, with pressure on
          decentralized apps and front-end services to introduce more stringent
          controls. While increased oversight poses challenges, it may also
          validate cryptocurrencies by putting them on firmer legal footing.
        </Typography>
      </Box>
      <ReactWordcloud words={data} />
    </Box>
  );
}
