import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>; // You can customize this loading screen
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return null; // Return null while redirecting to prevent rendering the protected content
  }

  return children;
};

export default ProtectedRoute;
