import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import EmailComponent from "../../components/email";

import { getEmails, getEmail, putEmail, getEmailsCount } from "../../api";

export default function EmailView() {
  const [emailLoading, setEmailLoading] = useState(true); // State to store user data
  const [emails, setEmails] = useState([]); // State to store user data
  const [emailIndex, setEmailIndex] = useState(null); // State to store user data
  const [email, setEmail] = useState(null); // State to store user data
  const [emailCount, setEmailCount] = useState(null); // State to store user data
  const [emailDoneCount, setEmailDoneCount] = useState(null); // State to store user data

  useEffect(() => {
    getEmails({ interesting: { $exists: false } }).then((myEmails) => {
      setEmails(myEmails);
      setEmailIndex(0);
    });
    getEmailsCount().then((count) => setEmailCount(count));
    getEmailsCount({ interesting: { $exists: true } }).then((count) =>
      setEmailDoneCount(count)
    );
  }, []);

  useEffect(() => {
    if (emailIndex === null) {
      return;
    }
    setEmailLoading(true);
    getEmail(emails[emailIndex]._id).then((myEmail) => {
      setEmail(myEmail);
      setEmailLoading(false);
    });
  }, [emailIndex, emails]);

  const markInteresting = async (interesting) => {
    if (emailLoading) {
      return;
    }
    setEmailLoading(true);
    await putEmail({ ...email, interesting });
    setEmailDoneCount(emailDoneCount + 1);
    setEmailLoading(false);
    setEmailIndex(emailIndex + 1);
    window.scrollTo(0, 0);
  };
  const handleMarkInteresting = () => markInteresting(true);
  const handleMarkBoring = () => markInteresting(false);

  return (
    <Box>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          pb: 2,
          bgcolor: "background.default",
          zIndex: 500,
        }}
      >
        <Box sx={{ width: "100%", mb: 2 }}>
          {emailCount && emailDoneCount && (
            <Box>
              <Typography>
                {emailDoneCount} of {emailCount}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={(100 / emailCount) * emailDoneCount}
              />
            </Box>
          )}
        </Box>
        <Box>
          <Stack spacing={2} direction="row">
            <Button
              variant="contained"
              color="success"
              onClick={handleMarkInteresting}
              disabled={emailLoading}
            >
              Interesting
            </Button>
            <Button
              variant="outlined"
              onClick={handleMarkBoring}
              disabled={emailLoading}
            >
              Boring
            </Button>
          </Stack>
        </Box>
      </Box>
      <Box>
        {emailLoading && <CircularProgress />}
        {email && <EmailComponent email={email} />}
      </Box>
    </Box>
  );
}
